<template>
  <v-container fluid>
    <v-row class="mt-6 px-5">
      <v-col xs="12" lg="4">
        <h1 class="secondary--text">Layout Importação</h1>
      </v-col>
    </v-row>
    <v-row class="px-8 mt-5 justify-space-between">
      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="6"
        cols="12"
        class="d-flex flex-row align-center pl-0 justify-space-between">
        <v-btn
          :disabled="editingOrCoping"
          @click="selectTypeLayoutUser('financialGroup')"
          :height="56"
          :class="{
            'rounded-lg mr-1': true,
            'primary': typeSelected === 'financialGroup'
          }"
          style="width: 50%;">
          Cliente
        </v-btn>

        <v-btn
          :disabled="editingOrCoping"
          @click="selectTypeLayoutUser('insuranceCarrier')"
          :height="56"
          :class="{
            'rounded-lg ml-1': true,
            'primary': typeSelected === 'insuranceCarrier'
          }"
          style="width: 50%;">
          Operadora
        </v-btn>
      </v-col>

      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="6"
        cols="12">
        <v-autocomplete
          v-if="typeSelected === 'financialGroup'"
          class="rounded-lg"
          v-model="financialGroupIds"
          :items="financialGroups"
          :loading="loadingParams"
          item-value="id"
          item-text="name"
          @input="findByFilter"
          item-color="textPrimary"
          outlined
          hide-details
          color="textPrimary"
          append-icon="fas fa-chevron-down"
          label="Grupo Financeiro"
          placeholder="Selecione o grupo financeiro"
          width="100%"
          multiple
        />

        <v-autocomplete
          v-if="typeSelected === 'insuranceCarrier'"
          class="rounded-lg"
          v-model="insuranceCarrierIds"
          :items="insuranceCarriers"
          :loading="loadingParams"
          item-value="id"
          item-text="xipp_commercial_name"
          @input="findByFilter"
          item-color="textPrimary"
          outlined
          hide-details
          color="textPrimary"
          append-icon="fas fa-chevron-down"
          label="Operadora"
          placeholder="Selecione a Operadora"
          width="100%"
          multiple
        />
      </v-col>

      <v-col
        xl="4"
        lg="4"
        md="9"
        sm="8"
        cols="12">
        <AdvancedSearch
          @result="resultAdvancedSearch = $event"
          @resetAdvancedSearch="resetAdvancedSearch = $event"
          :resetAdvancedSearch="resetAdvancedSearch"
          :items="getFilters"
        />
      </v-col>

      <v-col
        xl="2"
        lg="2"
        md="3"
        sm="4"
        cols="12"
        class="d-flex align-center justify-end pr-0">
        <v-btn
          class="text-center rounded-lg"
          color="primary"
          @click="setLayoutTypeInSession('isNew')"
          min-height="50">
          <v-icon
            style="font-size: 17px;"
            class="mr-2">
            fa-cog
          </v-icon>
          Configurar Layout
        </v-btn>
      </v-col>

      <v-row>
        <v-col
          xl="3"
          lg="3"
          md="6"
          sm="6"
          cols="12">
          <v-autocomplete
            class="rounded-lg"
            v-model="search.fileTypes"
            :items="[
              { id: 'CSV', name: 'CSV' },
              { id: 'TXT', name: 'TXT' },
              { id: 'XLS', name: 'XLS' },
              { id: 'XML', name: 'XML' },
              { id: 'XLSX', name: 'XLSX' },
            ]"
            item-value="id"
            item-text="name"
            @input="findByFilter"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            append-icon="fas fa-chevron-down"
            label="Tipo de Arquivo"
            placeholder="Selecione o Tipo de Arquivo"
            width="100%"
            multiple
          />
        </v-col>

        <v-col
          xl="3"
          lg="3"
          md="6"
          sm="6"
          cols="12">
          <v-autocomplete
            class="rounded-lg"
            v-model="search.layoutTypes"
            :items="layoutTypes"
            item-value="id"
            item-text="label"
            @input="findByFilter"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            append-icon="fas fa-chevron-down"
            label="Tipo do Layout"
            placeholder="Selecione o Tipo do Layout"
            width="100%"
            multiple
          />
        </v-col>

        <v-col
          xl="3"
          lg="3"
          md="6"
          sm="6"
          cols="12">
          <v-autocomplete
            class="rounded-lg"
            v-model="search.typeIntegrationValues"
            :items="typeIntegrations"
            item-value="id"
            item-text="label"
            @input="findByFilter"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            append-icon="fas fa-chevron-down"
            label="Tipo da Integração"
            placeholder="Selecione o Tipo da Integração"
            width="100%"
            multiple
          />
        </v-col>

        <v-col
          xl="3"
          lg="3"
          md="6"
          sm="6"
          cols="12">
          <v-text-field
            class="rounded-lg"
            v-model="search.name"
            :loading="loadingCron"
            @input="debounceFindByFilter"
            item-color="textPrimary"
            outlined
            hide-details
            color="textPrimary"
            label="Título do Layout"
            width="100%"
            multiple
          />
        </v-col>
      </v-row>
    </v-row>

    <v-container
      fluid
      class="px-5 pt-0 mt-5">
      <div
        class="white elevation-1 rounded-lg">
        <v-row
          class="mt-5"
          no-gutters>
          <v-sheet
            color="#D8D8D8"
            width="100%"
            height="60"
            class="d-flex align-center rounded-t-lg">
            <v-row>
              <v-col
                cols="6"
                class="wine-label font-weight-bold text-h6 pl-10">
                Selecione o(s) layout(s)
              </v-col>
              <v-col
                cols="6"
                class="wine-label font-weight-bold text-h6 pl-5">
                Configuração
              </v-col>
            </v-row>
          </v-sheet>
        </v-row>
        <v-row
          class="mt-0 px-3">
          <v-col
            cols="6"
            class="pt-0 px-0 overflow-auto"
            style="max-height: 500px;">
            <v-data-table
              v-model="layoutImportSelected"
              :items="layoutFilters"
              :page.sync="page"
              :items-per-page="10"
              hide-default-header
              :loading="loadingLayoutImport"
              item-key="layoutId"
              sort-by="action-active"
              show-select
              single-select
              checkbox-color="#62C756">
              <template v-slot:no-data>
                <span>
                  Nenhum layout foi encontrado.
                </span>
              </template>

              <template v-slot:item="{ item, isSelected, select }">
                <div
                  class="px-4 d-flex justify-space-between align-center"
                  style="height: 66px;">
                  <div
                    class="d-flex flex-row align-center justify-center">
                    <v-checkbox
                      :input-value="isSelected"
                      off-icon="mdi-radiobox-blank"
                      on-icon="mdi-radiobox-marked"
                      style="margin: 0px; padding: 0px;"
                      color="#63C755"
                      hide-details
                      @click="!isSelected ?  select(item) : layoutImportSelected = []"
                    />

                    <span
                      :class="{
                        'wineLabel--text': true,
                        'font-weight-medium': layoutImportSelected.some(el => el.layoutId === item.layoutId)
                      }">
                      {{ item.layoutName }} {{ item.layoutConfigType === 'FIXED' ? '(Layout manual)' : '' }}
                    </span>
                  </div>

                  <v-switch
                    inset
                    @click="updateLayoutStatus(item.layoutId, item.status, item)"
                    color="#62C756"
                    :value="item.status === 'ENABLED' ? true : false"
                    :input-value="item.status === 'ENABLED' ? true : false"
                  />
                </div>
              </template>
            </v-data-table>
          </v-col>

          <v-divider
            vertical
          />

          <v-col
            cols="6"
            class="pt-0 pr-0 overflow-auto"
            style="max-height: 350px; padding-left: 1px;">
            <v-slide-x-transition>
              <div>
                <div>
                  <v-sheet
                    height="66px"
                    class="d-flex flex-row px-4 align-center justify-space-between"
                    color="#EBEBEB"
                    v-if="layoutImportSelected.length > 0">
                    <v-btn
                      height="50"
                      width="200"
                      class="rounded-pill"
                      :disabled="layoutImportSelected[0].layoutConfigType === 'FIXED'"
                      @click="editOrCopyLayout(layoutImportSelected[0], 'isEdit')">
                      Editar
                    </v-btn>

                    <v-btn
                      icon
                      class="elevation-3 wineLabel--text white"
                      height="50"
                      width="50"
                      :disabled="layoutImportSelected[0].layoutConfigType === 'FIXED'"
                      @click="editOrCopyLayout(layoutImportSelected[0], 'isCopy')">
                      <v-icon
                        size="28">
                        mdi-content-copy
                      </v-icon>
                    </v-btn>

                    <v-text-field
                      :label="typeSelected === 'financialGroup' ? 'Busca de Grupo Financeiro' : 'Busca de Operadora'"
                      append-icon="mdi-magnify"
                      class="rounded-xl"
                      v-model="treeviewSearch"
                      outlined
                      height="50"
                      hide-details
                      style="max-width: 400px; color: #9FA9B8"
                    />
                  </v-sheet>
                </div>

                <div
                  class="mx-4">
                  <LayoutImportTreeView
                    :treeViewLayoutConfiguration="treeViewLayoutConfiguration"
                    :layoutImportSelected="layoutImportSelected"
                    :treeviewSearch="treeviewSearch"
                    :treeViewMounted="treeViewMounted"
                    @treeViewMounted="treeViewMounted = true"
                    @selection="selectionTreeView = $event"
                    @putMapping="putMapping"
                  />
                </div>
              </div>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-col cols="12" align="end">
      <v-footer
        class="transparent">
        <v-row class="d-flex pt-6 justify-end">
          <v-btn
            x-large
            outlined
            color="textPrimary"
            width="250px"
            @click="onClickCancel()"
          >
            Voltar
          </v-btn>
        </v-row>
      </v-footer>
    </v-col>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import LayoutService from '@/services-http/sdi/LayoutService';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import LayoutColumnService from '@/services-http/sdi/LayoutColumnService';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import AdvancedSearch from '@/components/AdvancedSearch/AdvancedSearch.vue';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import { mapGetters, mapMutations } from 'vuex';
import LayoutImportTreeView from '@/components/LayoutImportTreeview/LayoutImportTreeView.vue';
import DataLoadService from '@/services-http/sdi/DataLoadService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  components: {
    AdvancedSearch,
    LayoutImportTreeView,
    SnackbarCustomize,
  },

  data: () => ({
    treeViewMounted: false,
    debounceTimeout: null,
    loadingCron: false,
    financialGroupIds: [],
    insuranceCarrierIds: [],

    financialGroupId: null,
    insuranceCarrierId: null,


    search: {
      fileTypes: [],
      layoutTypes: [],
      typeIntegrationValues: [],
      name: null,
    },

    layoutTypes: [
      {id: 'NONE', label: 'Nenhum'},
      {id: 'POSITIONAL', label: 'Posicional'},
      {id: 'DELIMITED', label: 'Delimitado'},
    ],

    typeIntegrations: [
      { id: 'RECEIVEMENT', label: 'Recebimento',},
      { id: 'SHIPPING', label: 'Envio',  },
      { id: 'PAYROLL', label: 'Folha', },
    ],
    layoutFilter: null,
    layoutFilters: [],

    overlay: false,
    isRHProtegido: false,
    resetAdvancedSearch: false,
    selectionTreeView: [],
    resultAdvancedSearch: [],
    treeViewLayoutConfiguration: [],
    layoutImportSelected: [],
    treeviewSearch: null,
    page: 1,
    totalPages: 0,
    typeSelected: 'financialGroup',
    disableAutoCompleteFinancialGroup: true,
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingLayoutImport: false,
    financialGroups: [],
    insuranceCarriers: [],
    layoutImports: [],
    queryString: { isModel: false },
    dataLoadService: null,
    editingOrCoping: false,
    loadingParams: false,
  }),

  watch: {
    layoutImportSelected: {
      immediate: true,
      async handler(newValue) {
        if (newValue.length > 0) {
          this.treeViewMounted = false;

          this.selectionTreeView = [];

          await this.mountLayoutMapping();
          this.setTreeViewLayoutConfiguration();
          this.treeViewMounted = true;
        }
      }
    },

    typeSelected: {
      immediate: true,
      handler(newValue) {
        this.resetLayoutData();
      }
    },

    resultAdvancedSearch: {
      handler(newValue) {
        if (newValue) {
          this.findByFilter();
        }
      }
    },
  },

  mixins: [
    ContractsMixin,
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
  ],

  async mounted() {
    this.loadingParams = true;
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData()
    await this.getFinancialGroups();
    await this.setFileTypes();
    this.cleanLayout();
    this.loadingParams = false;
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },

  methods: {
    ...mapMutations({
      setLayoutEntityType: 'layout-import/setLayoutEntityType',
      resetLayoutImportModule: 'layout-import/resetModule',
      setLayoutColumns: 'layout-import/setLayoutColumns',
      setLayoutType: 'layout-import/setLayoutType',
      setLayouts: 'layout-import/setLayouts',
      setLayout: 'layout-import/setLayout',
    }),

    // Mountar layout mapping da treeview
    async mountLayoutMapping() {
      await this.layoutService.MappingLayout(this.layoutImportSelected[0].layoutId)
        .then((response) => {
          this.layoutImportSelected[0] = {
            ...this.layoutImportSelected[0],
            financialGroupIds: response.data.length > 0 ? response.data[0].financialGroupIds : [],
            contractIds: response.data.length > 0 ? response.data[0].contractIds : [],
            subcontractIds: response.data.length > 0 ? response.data[0].subcontractIds : [],
            carrierIds: response.data.length > 0 ? response.data[0].carrierIds : [],
          }
        });
    },

    // Mountar mapping da treeview
    mountMapping() {
      const data = {
        layoutId: this.layoutImportSelected[0].layoutId,
        carrierIds: [],
        financialGroups: []
      };

      const order = {
        financialGroup: 1,
        contract: 2,
        subContract: 3
      };

      const sync = [...this.selectionTreeView].sort((a, b) => order[a.type] - order[b.type])

      sync.forEach((el, index) => {
        let financialGroupProps = {
          financialGroupId: el.financialGroupId || el.id,
          contractId: null,
          subContractId: null,
        };

        switch (el.type) {
          case 'insuranceCarrier':
            data.carrierIds.push(el.id);
            data.financialGroups = [];
            break;

          case 'financialGroup':
            financialGroupProps.contractId = null;
            financialGroupProps.subContractId = null;
            if (!data.financialGroups.some(
              (item) =>
                (item.financialGroupId === el.financialGroupId || item.financialGroupId === el.id)
                && item.contractId === null
                && item.subContractId === null)) {
              data.financialGroups.push(financialGroupProps);
            }
            data.carrierIds = [];
            break;

          case 'contract':
            if (data.financialGroups.some(
                (item) => item.financialGroupId === el.financialGroupId && item.contractId === null)) {
              const financialGroupIndex = data.financialGroups.findIndex(
                (item) => item.financialGroupId === el.financialGroupId && !item.contractId);

              data.financialGroups[financialGroupIndex].contractId = el.nodeId;
            } else {
              financialGroupProps.contractId = el.nodeId;
              data.financialGroups.push(financialGroupProps);
            }
            data.carrierIds = [];
            break;

          case 'subContract':
            if (data.financialGroups.some(
              (item) => item.financialGroupId === el.financialGroupId
                && item.contractId === el.contractNodeId
                && item.subContractId === null)) {
              const financialGroupIndex = data.financialGroups.findIndex(
                (item) => item.financialGroupId === el.financialGroupId
                  && item.contractId === el.contractNodeId
                  && item.subContractId === null);

              data.financialGroups[financialGroupIndex].subContractId = el.nodeId;
            } else {
              financialGroupProps.contractId = el.contractNodeId;
              financialGroupProps.subContractId = el.nodeId;
              data.financialGroups.push(financialGroupProps);
            }
            data.carrierIds = [];
            break;
        }
      });

      return data;
    },

    // Atualizar mapping da treeview
    putMapping() {
      const data = this.mountMapping();

      this.layoutService.PutMapping([data]);
    },

    // Buscar tipos de arquivo
    async setFileTypes() {
      await this.dataLoadService.GetFileTypes();
    },

    debounceFindByFilter() {
      clearTimeout(this.debounceTimeout);

      this.loadingCron = true;

      this.debounceTimeout = setTimeout(() => {
        this.findByFilter();

        this.loadingCron = false;
      }, 2000);
    },

    // Buscar Layouts
    async findByFilter() {
      let params = {};

      for (let field of ['policies', 'subContractNumbers']) {
        if (this.resultAdvancedSearch.length > 0) {
          const fieldIndex = this.resultAdvancedSearch.findIndex(el => el.key === field);

          if (fieldIndex > -1) {
            params[field] = this.resultAdvancedSearch[fieldIndex].value;
          }
        }
      }

      for (let field of ['fileTypes', 'typeIntegrationValues', 'layoutTypes']) {
        if (this.search[field].length > 0) {
          params[field] = this.search[field];
        }
      }

      if (this.search && this.search.name) {
        params.name = this.search.name;
      }

      if (this.financialGroupIds.length > 0) {
        params.financialGroupIds = this.financialGroupIds;
      }

      if (this.insuranceCarrierIds.length > 0) {
        params.insuranceCarrierIds = this.insuranceCarrierIds;
      }

      if (this.typeSelected === 'financialGroup') {
        params.entityType = 'FINANCIAL_GROUP'
      } else {
        params.entityType = 'INSURANCE_CARRIER'
      }

      await this.layoutService.FindByFilter(params)
        .then((response) => {
          this.layoutFilters = response.data
        });
    },

    // Setar dados na session storage temporariamente, até que a página seja carregada
    handlePageRefresh() {
      if (Object.keys(this.layout) > 0) {
        sessionStorage.setItem('layout', JSON.stringify(this.layout))
      }
      if (Object.keys(this.layoutType).length > 0) {
        sessionStorage.setItem('layoutType', JSON.stringify(this.layoutType))
      }
    },

    // Salvar dados setados da sessionStorage no Vuex e limpar sessionStorage
    setSessionStoreData() {
      if (sessionStorage.getItem('layout')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('layout')));
        sessionStorage.removeItem('layout')
      }
      if (sessionStorage.getItem('layoutType')) {
        this.setLayoutType(JSON.parse(sessionStorage.getItem('layoutType')));
        sessionStorage.removeItem('layoutType')
      }
    },

    // Selecionar tipo de layout definido pelo usuário
    async selectTypeLayoutUser(type) {
      this.typeSelected = type;

      this.loadingParams = true

      this.setLayouts([]);
      if (this.typeSelected === 'financialGroup') {
        await this.getFinancialGroups();
      }
      if (this.typeSelected === 'insuranceCarrier') {
        await this.getInsuranceCarriers();
        this.insuranceCarrierId = '';
      }

      this.loadingParams = false

      this.resetLayoutData();

      this.setTreeViewLayoutConfiguration();
    },

    // Fazer busca de layout via Id do Layout
    async getLayoutsDataByLayoutId() {
      this.page = 1;
      this.loadingLayoutImport = true;
      this.queryString.id = this.layoutImportSelected[0].layoutId;

      return await this.layoutService.FindAllByFilter(this.queryString);
    },

    // Montar query via tipo do layout
    mountQuery(item) {
      if (item === 'financialGroup') {
        this.queryString.financialGroupId = this.financialGroupId;
        this.queryString.entityType = 'FINANCIAL_GROUP';
        this.queryString.page = this.page - 1;
        this.queryString.size = 10;
      } else {
        this.queryString.insuranceCarrierId = this.insuranceCarrierId;
        this.queryString.entityType = 'INSURANCE_CARRIER';
        this.queryString.page = this.page - 1;
        this.queryString.size = 10;
      }
    },

    // Ordenar layout por status
    sortTableByStatus() {
      this.layoutImports.sort((a, b) => (a.status > b.status ? 1 : -1));
    },

    // Atualizar status do layout
    updateLayoutStatus(id, status, item) {
      const statusFormated = status === 'ENABLED' ? 'DISABLED' : 'ENABLED';
      this.layoutService.UpdateLayoutStatus(id, statusFormated).then(() => {
        item.status = statusFormated;
      });
    },

    // Editar ou copiar um layout
    async editOrCopyLayout(item, type) {
      this.editingOrCoping = true;

      if (item) {
        this.setLayouts([]);
        this.layoutImports = [];

        let layoutError = false;

        await this.getLayoutsDataByLayoutId()
          .then((response) => {
            if (response && response.data && response.data.content && response.data.content.length > 0) {
              this.setLayouts(response.data.content)
              this.layoutImports = [ ...this.layoutsData ];
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              this.$refs.SnackbarCustomize.open('error', 'Este layout não pode ser editado, pois não possui nenhum relacionamento.');
              layoutError = true;
            }
          })
          .finally(() => {
            this.loadingLayoutImport = false;
          });

        this.sortTableByStatus();

        if (layoutError) return;

        const layout = this.layoutImports.find(el => el.id === item.layoutId);

        this.setLayoutEntityType(layout.entityType);

        this.overlay = true;
        this.setLayout(layout)

        if (!layout) {
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar o Layout.');
          this.overlay = false;
          return;
        }

        const query = `?id_layout=${layout.id}`;
        this.layoutColumnService.FindAllByFilter(query).then((response) => {
          const columns = response.data?.content?.[0]?.columns ?? [];
          const virtualColumns = response.data?.content?.[0]?.virtualColumns ?? [];
          this.layout.columns = columns;
          this.layout.virtualColumns = virtualColumns;
          sessionStorage.setItem('layout', JSON.stringify(this.layout));
          this.setLayoutColumns(columns);
          this.setLayoutTypeInSession(type);
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
        });
      }
      this.editingOrCoping = false;

    },

    // Setar tipo do layout na sessionStorage
    setLayoutTypeInSession(type) {
      if (this.typeSelected === 'financialGroup') {
        this.queryString.entityType = 'FINANCIAL_GROUP';
      }  else {
        this.queryString.entityType = 'INSURANCE_CARRIER';
      }

      const layoutType = {
        entityType: this.typeSelected === 'financialGroup'  ? 'FINANCIAL_GROUP' : 'INSURANCE_CARRIER',
        financialGroups: this.queryString.entityType === 'FINANCIAL_GROUP' && this.financialGroups ? this.financialGroups : [],
        insuranceCarriers: this.queryString.entityType === 'INSURANCE_CARRIER' && this.insuranceCarriers ? this.insuranceCarriers : [],
        financialGroupId: this.queryString.entityType === 'FINANCIAL_GROUP' && this.financialGroupId ? this.financialGroupId : null,
        insuranceCarrierId: this.queryString.entityType === 'INSURANCE_CARRIER' && this.insuranceCarrierId ? this.insuranceCarrierId : null,
        contracts: [],
        subContracts: [],
      };

      sessionStorage.setItem('layoutType', JSON.stringify(layoutType));
      this.setLayoutType(layoutType);

      if (type === 'isNew') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportConfigs' });
      } else if (type === 'isEdit') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnEditConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportColumnEditConfigs' });
      } else if (type === 'isCopy') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnCopyConfigs', query: { financialGroupId: this.financialGroupId } } : { name: 'LayoutImportColumnCopyConfigs' });
      }
    },

    // Limpar dados do layout do Vuex
    cleanLayout() {
      this.setLayout({})
    },

    // Resetar modulo LayoutImport e sair da tela de layout
    onClickCancel() {
      this.resetLayoutImportModule();
      this.setLayoutEntityType('');
      this.$router.push({ name: 'HomeAllMenus' });
    },

    // Resetar variaveis do componente
    resetLayoutData() {
      this.layoutImportSelected = [];
      this.layoutImports = [];
      this.selectionTreeView = [];
      this.resultAdvancedSearch = [];
      this.financialGroupIds = [];
      this.insuranceCarrierIds = [];
      this.search = {
        fileTypes: [],
        layoutTypes: [],
        typeIntegrationValues: [],
      };
      this.resetAdvancedSearch = true;
    },

    // Montar estrutura inicial do componente TreeView
    setTreeViewLayoutConfiguration() {
      const financialGroups = this.financialGroups.sort(
        (a, b) => (a.name > b.name ? 1 : -1));

      switch(this.typeSelected) {
        case 'financialGroup':
          this.treeViewLayoutConfiguration = [{
            id: 'parentFinancialGroups',
            type: 'financialGroups',
            name: `Selecionar todos os Grupos Financeiros (${financialGroups.length})`,
            children: financialGroups.map(
              (financialGroup) => ({
                id: financialGroup.id,
                name: financialGroup.name,
                type: 'financialGroup',
                children: [],
              })
            )
          }];

          break;

        case 'insuranceCarrier':
          const insuranceCarriers = this.insuranceCarriers.sort(
            (a, b) => (a.xipp_commercial_name > b.xipp_commercial_name ? 1 : -1));

          this.treeViewLayoutConfiguration = [{
            id: 'parentInsuranceCarriers',
            type: 'insuranceCarriers',
            name: `Selecionar todas as Operadoras (${insuranceCarriers.length})`,
            children: insuranceCarriers.map(
              insuranceCarrier => ({
                id: insuranceCarrier.id,
                name: insuranceCarrier.xipp_commercial_name,
                type: 'insuranceCarrier',
              })
            )
          }];

          break;
      }
    },
  },

  computed: {
    ...mapGetters({
      getFileTypes: 'dataLoad/fileTypesData',
      layoutsData: 'layout-import/layouts',
      layoutType: 'layout-import/layoutType',
      layout: 'layout-import/layout',
    }),

    getFilters() {
      switch(this.typeSelected) {
        case 'financialGroup':
          return [
            {id: 'policies', label: 'Apólice'},
            {id: 'subContractNumbers', label: 'Nº Subcontrato'},
          ];

        case 'insuranceCarrier':
          return [
            {id: 'fileTypes', label: 'Tipo do arquivo'},
          ];
      }
    },
  },

  created() {
    this.layoutService = new LayoutService();
    this.dataLoadService = new DataLoadService();
    this.layoutColumnService = new LayoutColumnService();
    this.isRHProtegido = this.$route.query.isRHProtegido;
  },
});
</script>
